<script lang="ts" setup>

</script>

<template>
  <div class="min-h-screen flex flex-col h-screen">
    <HeaderMain />
    <main class="p-4">
      <slot name="main" />
    </main>
    <slot />
  </div>
</template>

<style>

</style>
